export function formatFileSize(number) {
    if ((typeof number === 'string') && (/^[0-9]+$/.test(number))) {
      number = parseInt(number)
    }
    if (!(typeof number === 'number'))
      return number
    if (number >= 1024 && number < 1024 * 1024) {
      return (number / (1024)).toFixed(1) + 'K'
    } else if (number >= 1024 * 1024 && number < 1024 * 1024 * 1024) {
      return (number / (1024 * 1024)).toFixed(1) + 'M'
    } else if (number >= 1024 * 1024 * 1024) {
      return (number / (1024 * 1024 * 1024)).toFixed(1) + 'G'
    }
  }