<template>
  <input
    type="file"
    id="uploader"
    @change="fileChange"
  >
  <span v-show="ready">文件大小：{{ filesize }}  分辨率：{{ origin_width }}x{{ origin_height }}</span>
  <div class="crf-slider">
    <span class="demonstration">crf(质量参数，0是无损，51是极差，17～18在视觉上几乎感觉不到损失)</span>
    <el-slider
      v-model="crf"
      :min=0
      :max=51
      :marks="marks"
      size="small" />
  </div>
  <div class="crf-slider">
    <span class="demonstration">宽度</span>
    <el-select
      v-model="target_width"
      filterable
      allow-create
      default-first-option
      placeholder="选择转码后的视频宽度"
    >
      <el-option
        v-for="item in widthOptions"
        :key="item.key"
        :label="item.display_name"
        :value="item.key"
      />
    </el-select>
  </div>
  <button
    @click="transcode"
    :disabled="ready==false"
    :loading="doing"
  >
    开始转码
  </button>
  <p>
    {{ message }}
  </p>
  <p v-show="ratio">
    {{ ratio }}%
  </p>
  <video
    v-show="video"
    id="player"
    :src="video"
    controls
  />
  <br>
  {{ newFilesize }}
</template>

<script>
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import { defineComponent, ref } from "vue";
import { formatFileSize } from "./utils";

export default defineComponent({
  name: "App",
  setup() {
    // app state
    const message = ref("选择文件和参数后点击开始转码。");
    let video = ref(null);
    let ready = ref(false);
    let doing = ref(false);
    const ratio = ref(0);
    const cost = ref(0);
    const crf = ref(23);
    const marks = {0: "0", 18: "18", 23: "23", 51: "51"};
    const widthOptions = ref([
      { key: 'keep', display_name: '保持不变' },
    ]);
    const filesize = ref(null);
    const newFilesize = ref(null);
    const origin_width = ref(null);
    const origin_height = ref(null);
    const target_width = ref("keep");
    const ffmpeg = createFFmpeg({
      log: true,
      progress: (p) => {
        ratio.value = Math.round(p.ratio * 100);
        if (p.time !== undefined) {
          cost.value = Math.round(p.time);
        }
      }
    });
    const init = async () => {
      message.value = "加载环境……";
      await ffmpeg.load();
      message.value = "选择文件和参数后点击开始转码。";
    };
    init();
    const filterAsp = (w, h) => {
      widthOptions.value = [
        { key: "keep", display_name: "保持不变" },
      ];
      let standardWidths = [1920, 1440, 1280, 1080, 854, 720, 640, 480, 360];
      standardWidths.forEach(element => {
        if (element < w) {
          let t_h = element * h / w;
          if (t_h % 2 == 0) {
            widthOptions.value.push({ key: element, display_name: element + "x" + t_h })
          }
        }
      })
    };
    const fileChange = () => {
      if (uploader.files[0]) {
        ready.value=true;
        filesize.value = formatFileSize(uploader.files[0].size);
        const url = URL.createObjectURL(uploader.files[0]);
        const $video = document.createElement("video");
        $video.src = url;
        $video.addEventListener("loadedmetadata", function () {
          origin_width.value = this.videoWidth;
          origin_height.value = this.videoHeight;
          filterAsp(origin_width.value, origin_height.value);
        });
      } else {
        ready.value=false;
      }
    };
    const resetState = () => {
      widthOptions.value = [
        { key: "keep", display_name: "保持不变" },
      ]
    };
    const transcode = async () => {
      const { name } = uploader.files[0];
      doing.value = true;
      
      message.value = "开始转码ing……";
      ffmpeg.FS("writeFile", name, await fetchFile(uploader.files[0]));
      if (target_width.value == "keep") {
        await ffmpeg.run("-i", name, "-crf", crf.value.toString(), "test.mp4");
      } else {
        await ffmpeg.run("-i", name, "-crf", crf.value.toString(), "-vf", "scale="+target_width.value+":-1", "test.mp4");
        
      }
      message.value = "完成转码。用时：" + cost.value + "秒";
      const data = ffmpeg.FS("readFile", "test.mp4");
      const blob = new Blob([data.buffer], { type: "video/mp4" })
      newFilesize.value = formatFileSize(blob.size);
      video.value = URL.createObjectURL(
        blob
      );
      doing.value = false;
    };
    return {
      video,
      ready,
      doing,
      ratio,
      cost,
      crf,
      marks,
      widthOptions,
      filesize,
      newFilesize,
      origin_width,
      origin_height,
      target_width,
      fileChange,
      message,
      transcode,
    };
  },
});
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.crf-slider {
  display: flex;
  align-items: center;
}
.crf-slider .el-slider {
  margin-top: 0;
  margin-left: 12px;
  margin-bottom: 20px;
}
.crf-slider .demonstration {
  font-size: 14px;
  color: var(--el-text-color-secondary);
  line-height: 44px;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0;
}
.crf-slider .demonstration + .el-slider {
  flex: 0 0 70%;
}
</style>
